import { Event as XEvent } from './types';

type PageViewHandler = (event: XEvent) => Promise<void>;

interface PageTrackerOpts {
  isSPA: boolean
  onPageView: PageViewHandler
}

export const createPageTracker = (opts: PageTrackerOpts) => {
  if (!window.addEventListener || !history.pushState) return;

  const getUrl = () => {
    let url = '';
    if (window && window.location) {
      const { origin, pathname } = window.location;
      url = `${origin}${pathname}`;
    }

    return url;
  };

  const trackPage = () => {
    const currUrl = getUrl();
    const prevUrl = window.sessionStorage.getItem('xf-previous-url');
    if (currUrl !== prevUrl) {
      window.sessionStorage.setItem('xf-previous-url', currUrl);
      opts.onPageView({
        event_type: 'pageView',
        event_version: '1.0',
        event_timestamp: +new Date(),
        attributes: { url: currUrl },
      });
    }
  };

  if (opts.isSPA) {
    const prevPushState = window.history.pushState.bind(window.history);
    const prevReplaceState = window.history.replaceState.bind(window.history);
    window.history.pushState = (...args) => {
      trackPage();
      prevPushState(...args);
    };
    window.history.replaceState = (...args) => {
      trackPage();
      prevReplaceState(...args);
    };
    window.addEventListener('popstate', trackPage);
  }

  trackPage();
};
