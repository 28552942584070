import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import muiVars from './theme.sass';

export const createThemeOpts = (): ThemeOptions => ({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: Number(muiVars['breakpoint-xs']),
      sm: Number(muiVars['breakpoint-sm']),
      md: Number(muiVars['breakpoint-md']),
      lg: Number(muiVars['breakpoint-lg']),
      xl: Number(muiVars['breakpoint-xl']),
    },
  },
  direction: muiVars.direction,
  mixins: {
    toolbar: {
      minHeight: muiVars['mixin-toolbar-minHeight'],
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: muiVars['mixin-toolbar-xs-minHeight'],
      },
      '@media (min-width:600px)': {
        minHeight: muiVars['mixin-toolbar-sm-minHeight'],
      },
    },
  },
  palette: {
    common: {
      black: muiVars['palette-common-black'],
      white: muiVars['palette-common-white'],
    },
    type: muiVars['palette-type'],
    primary: {
      light: muiVars['palette-primary-light'],
      main: muiVars['palette-primary-main'],
      dark: muiVars['palette-primary-dark'],
      contrastText: muiVars['palette-primary-contrastText'],
    },
    secondary: {
      light: muiVars['palette-secondary-light'],
      main: muiVars['palette-secondary-main'],
      dark: muiVars['palette-secondary-dark'],
      contrastText: muiVars['palette-secondary-contrastText'],
    },
    error: {
      light: muiVars['palette-error-light'],
      main: muiVars['palette-error-main'],
      dark: muiVars['palette-error-dark'],
      contrastText: muiVars['palette-error-contrastText'],
    },
    warning: {
      light: muiVars['palette-warning-light'],
      main: muiVars['palette-warning-main'],
      dark: muiVars['palette-warning-dark'],
      contrastText: muiVars['palette-warning-contrastText'],
    },
    info: {
      light: muiVars['palette-info-light'],
      main: muiVars['palette-info-main'],
      dark: muiVars['palette-info-dark'],
      contrastText: muiVars['palette-info-contrastText'],
    },
    success: {
      light: muiVars['palette-success-light'],
      main: muiVars['palette-success-main'],
      dark: muiVars['palette-success-dark'],
      contrastText: muiVars['palette-success-contrastText'],
    },
    grey: {
      50: muiVars['palette-grey-50'],
      100: muiVars['palette-grey-100'],
      200: muiVars['palette-grey-200'],
      300: muiVars['palette-grey-300'],
      400: muiVars['palette-grey-400'],
      500: muiVars['palette-grey-500'],
      600: muiVars['palette-grey-600'],
      700: muiVars['palette-grey-700'],
      800: muiVars['palette-grey-800'],
      900: muiVars['palette-grey-900'],
      A100: muiVars['palette-grey-A100'],
      A200: muiVars['palette-grey-A200'],
      A400: muiVars['palette-grey-A400'],
      A700: muiVars['palette-grey-A700'],
    },
    contrastThreshold: Number(muiVars['palette-contrastThreshold']),
    tonalOffset: Number(muiVars['palette-tonalOffset']),
    text: {
      primary: muiVars['palette-text-primary'],
      secondary: muiVars['palette-text-secondary'],
      disabled: muiVars['palette-text-disabled'],
      hint: muiVars['palette-text-hint'],
    },
    divider: muiVars['palette-divider'],
    background: {
      paper: muiVars['palette-background-paper'],
      default: muiVars['palette-background-default'],
    },
    action: {
      active: muiVars['palette-action-active'],
      hover: muiVars['palette-action-hover'],
      hoverOpacity: Number(muiVars['palette-action-hoverOpacity']),
      selected: muiVars['palette-action-selected'],
      selectedOpacity: Number(muiVars['palette-action-selectedOpacity']),
      disabled: muiVars['palette-action-disabled'],
      disabledBackground: muiVars['palette-action-disabledBackground'],
      disabledOpacity: Number(muiVars['palette-action-disabledOpacity']),
      focus: muiVars['palette-action-focus'],
      focusOpacity: Number(muiVars['palette-action-focusOpacity']),
      activatedOpacity: Number(muiVars['palette-action-activatedOpacity']),
    },
  },
  shadows: [
    muiVars['shadows-0'],
    muiVars['shadows-1'],
    muiVars['shadows-2'],
    muiVars['shadows-3'],
    muiVars['shadows-4'],
    muiVars['shadows-5'],
    muiVars['shadows-6'],
    muiVars['shadows-7'],
    muiVars['shadows-8'],
    muiVars['shadows-9'],
    muiVars['shadows-10'],
    muiVars['shadows-11'],
    muiVars['shadows-12'],
    muiVars['shadows-13'],
    muiVars['shadows-14'],
    muiVars['shadows-15'],
    muiVars['shadows-16'],
    muiVars['shadows-17'],
    muiVars['shadows-18'],
    muiVars['shadows-19'],
    muiVars['shadows-20'],
    muiVars['shadows-21'],
    muiVars['shadows-22'],
    muiVars['shadows-23'],
    muiVars['shadows-24'],
  ],
  typography: {
    htmlFontSize: Number(muiVars['typography-htmlFontSize']),
    fontFamily: muiVars['typography-fontFamily'],
    fontSize: Number(muiVars['typography-fontSize']),
    fontWeightLight: Number(muiVars['typography-fontWeightLight']),
    fontWeightRegular: Number(muiVars['typography-fontWeightRegular']),
    fontWeightMedium: Number(muiVars['typography-fontWeightMedium']),
    fontWeightBold: Number(muiVars['typography-fontWeightBold']),
    h1: {
      fontFamily: muiVars['typography-h1-fontFamily'],
      fontWeight: Number(muiVars['typography-h1-fontWeight']),
      fontSize: muiVars['typography-h1-fontSize'],
      lineHeight: Number(muiVars['typography-h1-lineHeight']),
      letterSpacing: muiVars['typography-h1-letterSpacing'],
    },
    h2: {
      fontFamily: muiVars['typography-h2-fontFamily'],
      fontWeight: Number(muiVars['typography-h2-fontWeight']),
      fontSize: muiVars['typography-h2-fontSize'],
      lineHeight: Number(muiVars['typography-h2-lineHeight']),
      letterSpacing: muiVars['typography-h2-letterSpacing'],
    },
    h3: {
      fontFamily: muiVars['typography-h3-fontFamily'],
      fontWeight: Number(muiVars['typography-h3-fontWeight']),
      fontSize: muiVars['typography-h3-fontSize'],
      lineHeight: Number(muiVars['typography-h3-lineHeight']),
      letterSpacing: muiVars['typography-h3-letterSpacing'],
    },
    h4: {
      fontFamily: muiVars['typography-h4-fontFamily'],
      fontWeight: Number(muiVars['typography-h4-fontWeight']),
      fontSize: muiVars['typography-h4-fontSize'],
      lineHeight: Number(muiVars['typography-h4-lineHeight']),
      letterSpacing: muiVars['typography-h4-letterSpacing'],
    },
    h5: {
      fontFamily: muiVars['typography-h5-fontFamily'],
      fontWeight: Number(muiVars['typography-h5-fontWeight']),
      fontSize: muiVars['typography-h5-fontSize'],
      lineHeight: Number(muiVars['typography-h5-lineHeight']),
      letterSpacing: muiVars['typography-h5-letterSpacing'],
    },
    h6: {
      fontFamily: muiVars['typography-h6-fontFamily'],
      fontWeight: Number(muiVars['typography-h6-fontWeight']),
      fontSize: muiVars['typography-h6-fontSize'],
      lineHeight: Number(muiVars['typography-h6-lineHeight']),
      letterSpacing: muiVars['typography-h6-letterSpacing'],
    },
    subtitle1: {
      fontFamily: muiVars['typography-subtitle1-fontFamily'],
      fontWeight: Number(muiVars['typography-subtitle1-fontWeight']),
      fontSize: muiVars['typography-subtitle1-fontSize'],
      lineHeight: Number(muiVars['typography-subtitle1-lineHeight']),
      letterSpacing: muiVars['typography-subtitle1-letterSpacing'],
    },
    subtitle2: {
      fontFamily: muiVars['typography-subtitle2-fontFamily'],
      fontWeight: Number(muiVars['typography-subtitle2-fontWeight']),
      fontSize: muiVars['typography-subtitle2-fontSize'],
      lineHeight: Number(Number(muiVars['typography-subtitle2-lineHeight'])),
      letterSpacing: muiVars['typography-subtitle2-letterSpacing'],
    },
    body1: {
      fontFamily: muiVars['typography-body1-fontFamily'],
      fontWeight: Number(muiVars['typography-body1-fontWeight']),
      fontSize: muiVars['typography-body1-fontSize'],
      lineHeight: Number(muiVars['typography-body1-lineHeight']),
      letterSpacing: muiVars['typography-body1-letterSpacing'],
    },
    body2: {
      fontFamily: muiVars['typography-body2-fontFamily'],
      fontWeight: Number(muiVars['typography-body2-fontWeight']),
      fontSize: muiVars['typography-body2-fontSize'],
      lineHeight: Number(muiVars['typography-body2-lineHeight']),
      letterSpacing: muiVars['typography-body2-letterSpacing'],
    },
    button: {
      fontFamily: muiVars['typography-button-fontFamily'],
      fontWeight: Number(muiVars['typography-button-fontWeight']),
      fontSize: muiVars['typography-button-fontSize'],
      lineHeight: Number(muiVars['typography-button-lineHeight']),
      letterSpacing: muiVars['typography-button-letterSpacing'],
      textTransform: muiVars['typography-button-textTransform'],
    },
    caption: {
      fontFamily: muiVars['typography-caption-fontFamily'],
      fontWeight: Number(muiVars['typography-caption-fontWeight']),
      fontSize: muiVars['typography-caption-fontSize'],
      lineHeight: Number(muiVars['typography-caption-lineHeight']),
      letterSpacing: muiVars['typography-caption-letterSpacing'],
    },
    overline: {
      fontFamily: muiVars['typography-overline-fontFamily'],
      fontWeight: Number(muiVars['typography-overline-fontWeight']),
      fontSize: muiVars['typography-overline-fontSize'],
      lineHeight: Number(muiVars['typography-overline-lineHeight']),
      letterSpacing: muiVars['typography-overline-letterSpacing'],
      textTransform: muiVars['typography-overline-textTransform'],
    },
  },
  shape: {
    borderRadius: Number(muiVars['shape-borderRadius']),
  },
  transitions: {
    easing: {
      easeInOut: muiVars['transitions-easing-easeInOut'],
      easeOut: muiVars['transitions-easing-easeOut'],
      easeIn: muiVars['transitions-easing-easeIn'],
      sharp: muiVars['transitions-easing-sharp'],
    },
    duration: {
      shortest: Number(muiVars['transitions-duration-shortest']),
      shorter: Number(muiVars['transitions-duration-shorter']),
      short: Number(muiVars['transitions-duration-short']),
      standard: Number(muiVars['transitions-duration-standard']),
      complex: Number(muiVars['transitions-duration-complex']),
      enteringScreen: Number(muiVars['transitions-duration-enteringScreen']),
      leavingScreen: Number(muiVars['transitions-duration-leavingScreen']),
    },
  },
  zIndex: {
    mobileStepper: Number(muiVars['zIndex-mobileStepper']),
    speedDial: Number(muiVars['zIndex-speedDial']),
    appBar: Number(muiVars['zIndex-appBar']),
    drawer: Number(muiVars['zIndex-drawer']),
    modal: Number(muiVars['zIndex-modal']),
    snackbar: Number(muiVars['zIndex-snackbar']),
    tooltip: Number(muiVars['zIndex-tooltip']),
  },
} as ThemeOptions);

export default createTheme;
