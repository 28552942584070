import { createApi, Api } from './api';
import { Event, Location, XFAnalyticsOptions } from './types';

let api: Api | undefined;

const allowedEventPayloadTypes = ['number', 'string'];

export const init = async (orgId: string, appId: string, options?: XFAnalyticsOptions) => {
  if (api) return;
  api = createApi({
    orgId,
    appId,
    isSPA: !!(options && options.isSPA),
    disablePageTracker: !!(options && options.disablePageTracker),
    apiDomain: options?.apiDomain,
  });
};

export const raiseEvent = (eventName: string, payload?: { [key: string]: string | number }) => {
  const event: Event = { event_type: eventName, event_version: '1.0', event_timestamp: +new Date() };
  if (payload) {
    const { attributes, metrics } = Object.keys(payload).reduce((acc, key) => {
      const val = payload[key];
      const t = typeof val;
      if (!allowedEventPayloadTypes.includes(t)) {
        console.error(`[X-Functions] Event payload only accept strings or numbers. Key ${key} contains a value of type ${t} and will be ignored.`);
        return acc;
      }

      const payloadType = t === 'number' ? 'metrics' : 'attributes';

      return {
        ...acc,
        [payloadType]: {
          ...acc[payloadType],
          [key]: val,
        }
      };
    }, { attributes: {}, metrics: {} });

    event.attributes = attributes;
    event.metrics = metrics;
  }

  api?.addEvent(event);
};

export const identify = (userId: string, payload?: { [key: string]: string | number }, userAttributes?: { [name: string]: string[] }) => {
  api?.endpoint.setUser({
    UserId: userId,
    UserAttributes: userAttributes,
  });
  raiseEvent('identify', { ...payload, userId });
};

export const recordPurchase = (productId: string, currency: string = 'USD', itemPrice?: number, quantity?: number, transactionId?: string) => {
  raiseEvent('_monetization.purchase', {
    '_currency': currency,
    '_product_id': productId,
    ...(transactionId ? { '_transaction_id': transactionId } : {}),
    ...(itemPrice ? { '_item_price': itemPrice } : {}),
    ...(quantity ? { '_quantity': quantity } : {})
  });
};

export const setEndpointLocation = (location: Location) => api?.endpoint.setLocation(location);

export const setEndpointAttributes = (attributes: { [key: string]: string }) => api?.endpoint.setAttributes(attributes);

export const setEndpointMetrics = (metrics: { [key: string]: number }) => api?.endpoint.setMetrics(metrics);
