import { Event } from './types';

interface EventStoreProps {
  onProcess: (events: Event[]) => Promise<void>
}

function throttle<T, R>(fn: (...args: T[]) => R, wait = 300): (...args: T[]) => R | undefined {
  let throttled = false;
  return (...args: T[]): R | undefined => {
    // It's throttled - just return
    if (throttled) return undefined;

    // Apply function
    const res = fn(...args);

    // start throttling after a call
    throttled = true;
    setTimeout(() => {
      // Throttle it for `${wait}` ms
      throttled = false;
    }, wait);

    return res;
  };
}

export class EventStore {
  events: Event[];
  lastProcessed: number;
  onProcess: (events: Event[]) => Promise<void> | undefined;

  constructor(opts: EventStoreProps) {
    this.events = [];
    this.lastProcessed = 0;
    this.onProcess = throttle(opts.onProcess);
    setInterval(() => {
      this.flush();
    }, 300);
  }

  addEvent = async (event: Event) => {
    this.events.push(event);
    this.flush();
  }

  flush = async () => {
    const newLastProcessed = this.events.length;
    if (this.lastProcessed !== newLastProcessed) {
      const res = this.onProcess(this.events.slice(this.lastProcessed));
      if (typeof res === 'undefined') {
        return;
      }

      this.lastProcessed = newLastProcessed;
    }
  }
}
