import axios from 'axios';
import { Event as XEvent, Session, Endpoint, User, Location } from './types';
import { createSessionTracker, SessionTracker } from './sessionTracker';
import { createDeviceTracker } from './deviceTracker';
import { createEventTracker } from './eventTracker';
import { createPageTracker } from './pageTracker';
import { createEndpointTracker } from './endpointTracker';
import { EventStore } from './eventStore';

export interface CreateApiConfig {
  orgId: string
  appId: string
  isSPA: boolean
  disablePageTracker?: boolean
  apiDomain?: string
}

export interface Api {
  addEvent: (event: XEvent) => Promise<void>
  endpoint: {
    getEndpoint: () => Endpoint
    setUser: (user: User) => Endpoint
    setMetrics: (user: { [key: string]: number }) => Endpoint
    setAttributes: (user: { [key: string]: string }) => Endpoint
    setLocation: (location: Location) => Endpoint
  }
}

export const createApi = (config: CreateApiConfig) => {
  const api = axios.create({
    baseURL: `https://${config.orgId}.${config.apiDomain || 'api.x-f.io'}/events/${config.appId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const endpointTracker = createEndpointTracker();
  const deviceTracker = createDeviceTracker();
  let sessionTracker: SessionTracker, eventStore: EventStore;

  const onSessionChange = (eventName: string, session: Session) => eventStore && eventStore.addEvent({
    event_type: eventName,
    event_version: '1.0',
    event_timestamp: +new Date(),
    session,
  });

  const sendEvents = async (events: XEvent[]): Promise<void> => {
    const currentSession = sessionTracker && sessionTracker.getSession();
    const finalEvents = events.map(event => {
      if (!event.device) event.device = deviceTracker.getDevice();
      if (!event.session) event.session = currentSession;
      if (!event.attributes) event.attributes = {};
      if (!event.metrics) event.metrics = {};
      event.endpoint = endpointTracker.getEndpoint();

      return event;
    });

    if (!api) throw new Error('API not initialized');
    await api.post('', { events: finalEvents });
  };

  eventStore = new EventStore({
    onProcess: sendEvents,
  });
  sessionTracker = createSessionTracker(onSessionChange);
  createEventTracker({
    onEventRaised: eventStore.addEvent,
  });

  if (!config.disablePageTracker) {
    createPageTracker({
      isSPA: config.isSPA,
      onPageView: eventStore.addEvent,
    });
  }

  return {
    addEvent: eventStore.addEvent,
    endpoint: endpointTracker,
  };
};
