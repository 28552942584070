import { Device } from './types';

export interface DeviceTracker {
  getDevice: () => Device
}

const getBrowserAndVersion = (userAgent: string): { type: string, version: string } => {
  const operaMatch = /.+(Opera[\s[A-Z]*|OPR[\sA-Z]*)\/([0-9\.]+).*/i.exec(
    userAgent
  );
  if (operaMatch) {
    return { type: operaMatch[1], version: operaMatch[2] };
  }

  const ieMatch = /.+(Trident|Edge)\/([0-9\.]+).*/i.exec(userAgent);
  if (ieMatch) {
    return { type: ieMatch[1], version: ieMatch[2] };
  }

  const cfMatch = /.+(Chrome|Firefox|FxiOS)\/([0-9\.]+).*/i.exec(userAgent);
  if (cfMatch) {
    return { type: cfMatch[1], version: cfMatch[2] };
  }

  const sMatch = /.+(Safari)\/([0-9\.]+).*/i.exec(userAgent);
  if (sMatch) {
    return { type: sMatch[1], version: sMatch[2] };
  }

  const awkMatch = /.+(AppleWebKit)\/([0-9\.]+).*/i.exec(userAgent);
  if (awkMatch) {
    return { type: awkMatch[1], version: awkMatch[2] };
  }

  const anyMatch = /.*([A-Z]+)\/([0-9\.]+).*/i.exec(userAgent);
  if (anyMatch) {
    return { type: anyMatch[1], version: anyMatch[2] };
  }

  return { type: '', version: '' };
}

const getTimezone = () => {
  const tzMatch = /\(([A-Za-z\s].*)\)/.exec(new Date().toString());
  return tzMatch ? tzMatch[1] || '' : '';
}

export const createDeviceTracker = (): DeviceTracker => {
  let device: Device;

  if (window && window.navigator) {
    const { platform, product, vendor, userAgent, language } = window.navigator;
    const browser = getBrowserAndVersion(userAgent);
    const timezone = getTimezone();
    device = {
      make: (product || vendor) as string,
      model: browser.type,
      version: browser.version,
      language,
      timezone,
      platform: {
        name: platform,
      },
    };
  }

  return {
    getDevice: () => device,
  };
};
