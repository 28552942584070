import { Event as XEvent } from './types';
import { delegate } from 'dom-utils';

type EventHandler = (event: XEvent) => Promise<void>;

interface EventTrackerOpts {
  onEventRaised: EventHandler
}


function parseAttr<T> (attr: string, transform: (val: string) => T): { [key: string]: T } {
  return attr.split(/\s*,\s*/).reduce((acc: object, curr: string): object => {
    const [key, value] = curr.trim().split(/\s*:\s*/);
    if (!key) return acc;

    return { ...acc, [key]: transform(value) };
  }, {});
};

export const createEventTracker = (opts: EventTrackerOpts) => {
  if (!document || !document.body) return;

  const handleEvent = ({}={}, element: HTMLElement) => {
    const name = element.getAttribute('data-xf-name');
    if (!name) return;

    const attrs = element.getAttribute('data-xf-attrs') || '';
    const metrics = element.getAttribute('data-xf-metrics') || '';

    opts.onEventRaised({
      event_type: name,
      event_version: '1.0',
      event_timestamp: +new Date(),
      metrics: parseAttr(metrics, Number),
      attributes: parseAttr(attrs, String),
    });
  };

  delegate(
    document,
    'click',
    '[data-xf-on]',
    handleEvent,
    { composed: true, useCapture: true },
  );
};
