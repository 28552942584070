import { v4 } from 'uuid';
import { Session } from './types';

export interface SessionTracker {
  hiddenProp: string
  visibilityChangeProp: string
  getSession: () => Session
}

const FIVE_MINUTES = 300000;
const SESSION_REPLAY_MAX_TIME = FIVE_MINUTES;

export const createSessionTracker = (onChange: (event: string, session: Session) => void): SessionTracker => {
  if (!document || !document.addEventListener) throw new Error('Cannot create session tracker - unsupported environment');

  let hiddenProp: string, visibilityChangeProp: string;

  if (typeof document.hidden !== 'undefined') {
    hiddenProp = 'hidden';
    visibilityChangeProp = 'visibilitychange';
  } else if ('msHidden' in document) {
    hiddenProp = 'msHidden';
    visibilityChangeProp = 'msvisibilitychange';
  } else if ('webkitHidden' in document) {
    hiddenProp = 'webkitHidden';
    visibilityChangeProp = 'webkitvisibilitychange';
  } else {
    throw new Error('Cannot create session tracker - unsupported environment');
  }

  const tmpSessionStopTime = window.sessionStorage.getItem('xf-session-stop');
  let sessionId = window.sessionStorage.getItem('xf-session-id') || v4();
  let sessionStartTime = Number(window.sessionStorage.getItem('xf-session-start') || new Date());
  let sessionStopTime: number | undefined = tmpSessionStopTime ? Number(tmpSessionStopTime) : undefined;
  let justLoaded = true;

  const getSession = (): Session => ({
    session_id: sessionId,
    start_timestamp: sessionStartTime,
    stop_timestamp: sessionStopTime,
  });

  const visibilitychange = () => {
    let event = '';
    if (document.visibilityState === hiddenProp) {
      // Session stopped
      event = '_session.stop';
      sessionStopTime = +new Date();
    } else {
      // Session restarted
      event = '_session.start';
      sessionStartTime = +new Date();

      // Just loaded and the old sessionStartTime is older than 5 minutes - start a new one
      if (justLoaded && !sessionStopTime && Math.abs(+new Date() - sessionStartTime) > SESSION_REPLAY_MAX_TIME) {
        sessionId = v4();
      }

      // If more than 5 minutes elapsed since session stopping, start a new one. Otherwise, just continue the previous session
      if (Math.abs(sessionStartTime - (sessionStopTime || 0)) > SESSION_REPLAY_MAX_TIME) {
        sessionId = v4();
      }

      // Restart the 'session stop' timer
      sessionStopTime = undefined;
      justLoaded = false;
    }

    window.sessionStorage.setItem('xf-session-stop', String(sessionStopTime || ''));
    window.sessionStorage.setItem('xf-session-id', sessionId);
    window.sessionStorage.setItem('xf-session-start', String(sessionStartTime));
    onChange(event, getSession());
  };

  // Trigger the initial event
  visibilitychange();

  const beforeunload = () => {
    sessionStopTime = +new Date();
    onChange('_session.stop', getSession());
  }

  document.addEventListener(visibilityChangeProp, visibilitychange, false);
  window.addEventListener('beforeunload', beforeunload, false);

  return {
    visibilityChangeProp,
    hiddenProp,
    getSession,
  };
};
