import { Endpoint, User, Location } from './types';

export interface EndpointTracker {
  getEndpoint: () => Endpoint
  setUser: (user: User) => Endpoint
  setMetrics: (user: { [key: string]: number }) => Endpoint
  setAttributes: (user: { [key: string]: string }) => Endpoint
  setLocation: (location: Location) => Endpoint
}

export const createEndpointTracker = (): EndpointTracker => {
  let endpoint: Endpoint = {};

  return {
    getEndpoint: () => endpoint,
    setUser: (user: User) => {
      endpoint.User = user;
      return endpoint;
    },
    setMetrics: (metrics: { [key: string]: number }) => {
      endpoint.Metrics = metrics;
      return endpoint;
    },
    setAttributes: (attributes: { [key: string]: string }) => {
      endpoint.Attributes = attributes;
      return endpoint;
    },
    setLocation: (location: Location) => {
      endpoint.Location = location;
      return endpoint;
    },
  };
};
